header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*=============== CTA ===============*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/*=============== SOCIAL ===============*/
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.social::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: (var(--color-primary), transparent);
}

/*=============== ME ===============*/
.me {
  background: linear-gradient(--color-primary);
  width: 22rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  // border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/*=============== SCROLL DOWN ===============*/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  color: yellow;
}

/*MEDIA QUERIES (MEDIUM DEVICES)*/
@media screen and (max-width: 1024px){
  header {
    height: 100vh;
  }
}

/*MEDIA QUERIES (SMALL DEVICES)*/
@media screen and (max-width: 600px){
  header {
    height: 100vh;
  }
  .social,
  .scroll__down {
    display: none;
  }
}